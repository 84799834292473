import { Injectable } from "@angular/core"

import { ChangePlanHistoryDto, ExportChangePlanHistoryDto, ExportChangePlanHistoryResultDto, FindPromotionDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class ChangePlanHistoryService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find({ limit, offset, searchText }: FindPromotionDto) {
    return await this.httpService.get<PageResultDto<ChangePlanHistoryDto>>('/admin/change-plan/history', { limit, offset, searchText })
  }

  async findOne(id: string) {
    return await this.httpService.get<ChangePlanHistoryDto>(`/admin/change-plan/history/${id}`)
  }

  async exportExcel(dateFilterDto: ExportChangePlanHistoryDto) {
    return await this.httpService.get<ExportChangePlanHistoryResultDto>(`/admin/change-plan/history/export`, dateFilterDto)
  }
}