import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { PageDto } from '../dto/page.dto'

import { PageResultDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'
@Injectable()
export class PageService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<PageDto>>('/admin/page', pagingDto)
  }

  async findOne(pageId: string) {
    return this.httpService.get<PageDto>(`/admin/page/${pageId}`)
  }

  async update(pageId: string, updatePageDto: PageDto) {
    return await this.httpService.put<PageDto>(`/admin/page/${pageId}`, updatePageDto)
  }
}
