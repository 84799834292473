import { HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { HTTP_HEADER_BRANCH_KEY } from "../core"

import { FindPlanDto, PageResultDto, PlanDto, SetPlanBenefitDto, UpdatePlanDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class PlanService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset, searchText }: FindPlanDto) {
    return await this.httpService.get<PageResultDto<PlanDto>>('/admin/plan', { limit, offset, searchText })
  }

  async findOne(id: string) {
    return await this.httpService.get<PlanDto>(`/admin/plan/${id}`)
  }

  async update(id: string, updatePlanDto: UpdatePlanDto) {
    return await this.httpService.put<boolean>(`/admin/plan/${id}`, updatePlanDto)
  }

  async setBenefit(id: string, setPlanBenefitDto: SetPlanBenefitDto) {
    return await this.httpService.post<Boolean>(`/admin/plan/${id}/benefits`, setPlanBenefitDto)
  }

  async sync(branch: string) {
    let headers = new HttpHeaders()

    headers = headers.set(HTTP_HEADER_BRANCH_KEY, branch)

    return await this.httpService.post<Boolean>(`/admin/plan/sync`, {}, {}, headers)
  }

  async setBannerImage(id: string, fileId: string) {
    return await this.httpService.put<boolean>(`/admin/plan/${id}/banner-image`, { fileId })
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/plan/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/plan/${id}/disable`)
  }

  async publish(id: string) {
    return await this.httpService.patch<Boolean>(`/admin/plan/${id}/publish`)
  }
}