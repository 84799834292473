import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { UpdateProfileDto, ProfileDto } from './../dto'

import { HttpService } from './http.service'
import { FileService } from './file.service'
import { AuthStore } from '@api/store/auth'

@Injectable()
export class ProfileService {
  constructor(
    private authStore: AuthStore,
    private httpService: HttpService,
    private fileService: FileService
    ) {
  }

  async current() {
    const user = await this.httpService.get<ProfileDto>('/admin/profile')
    const authUser = _.clone(user)

    this.authStore.update({ user: authUser })

    return user
  }

  async update({ fullName, email }: UpdateProfileDto) {
    return await this.httpService.put<Boolean>('/admin/profile', { fullName, email })
  }

  async uploadPhoto(fileId: string) {
    return await this.httpService.post<Boolean>('/admin/profile/photo', { fileId })
  }

  async updatePassword(currentPassword: string, newPassword: string) {
    return await this.httpService.post<Boolean>('/admin/profile/update-password', {
      currentPassword,
      newPassword
    })
  }
}
