import { Injectable } from "@angular/core"

import { ExportTopupCreditHistoryDto, ExportTopupCreditHistoryResultDto, FindPromotionDto, PageResultDto, TopupCreditHistoryDto } from "../dto"
import { HttpService } from "./http.service"

@Injectable()
export class TopupCreditHistoryService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find({ limit, offset, searchText }: FindPromotionDto) {
    return await this.httpService.get<PageResultDto<TopupCreditHistoryDto>>('/admin/topup-credit/history', { limit, offset, searchText })
  }

  async findOne(id: string) {
    return await this.httpService.get<TopupCreditHistoryDto>(`/admin/topup-credit/history/${id}`)
  }

  async exportExcel(dateFilterDto: ExportTopupCreditHistoryDto) {
    return await this.httpService.get<ExportTopupCreditHistoryResultDto>(`/admin/topup-credit/history/export`, dateFilterDto)
  }
}
