import { Injectable } from "@angular/core"

import { DashboardDto, FindDashboardDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class DashboardService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ startDate, endDate }: FindDashboardDto) {
    return await this.httpService.get<DashboardDto>('/admin/dashboard', { startDate, endDate })
  }
}