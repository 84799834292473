export class TranslationDto<T = any> {
  en: T
  kh: T
  cn: T

  public static create(en?: any, kh? : any, cn?: any) {
    const translation = new TranslationDto()

    translation.en = en || ''
    translation.kh = kh || ''
    translation.cn = cn || ''

    return translation
  }
}

export enum Branch {
  IH = 'IH',
  ONLINE = 'Online'
}