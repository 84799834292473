import { Injectable } from "@angular/core"

import { ExportTicketDto, ExportTicketResultDto, FindPromotionDto, FindTicketDto, PageResultDto, TicketDetailDto, TicketDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class TicketService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset, searchText, status, complaintTypeId }: FindTicketDto) {
    return await this.httpService.get<PageResultDto<TicketDto>>('/admin/tickets', { limit, offset, searchText, status, complaintTypeId })
  }

  async findOne(id: string) {
    return await this.httpService.get<TicketDetailDto>(`/admin/tickets/${id}`)
  }

  async exportExcel(dateFilterDto: ExportTicketDto) {
    return await this.httpService.get<ExportTicketResultDto>(`/admin/tickets/export`, dateFilterDto)
  }
}
