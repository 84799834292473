import { Injectable } from '@angular/core'
import { HttpHeaders } from '@angular/common/http'

import { FileDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class FileService {
  constructor(
    private httpService: HttpService
    ) {
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  async upload(file: File, isTemporary?: boolean, path?: string) {
    return await this.uploadPath('/files/upload', file, isTemporary, path)
  }

  async uploadPath(uploadPath: string, file: File, isTemporary: boolean, path?: string) {
    const formData = new FormData()
    const headers = new HttpHeaders()
      
    formData.append('file', file)
    formData.append('isTemporary', isTemporary + '')
    
    if (path) {
      formData.append('path', path)
    }

    return await this.httpService.post<FileDto>(uploadPath, formData, {}, headers)
  }

  async findMultiple(fileIds: string[]) {
    return await this.httpService.get<FileDto[]>('/files/multiple', { fileIds })
  }

  async delete(id: string) {
    return await this.httpService.delete('/files/' + id)
  }
}

